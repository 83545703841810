<template>
  <section class="AmbulanciasTrasladosLocales empresas__inner container mt24 mb24">
    <ButtonBackHome
      to="/empresas/ambulancia"
      text="Regresar"
    />
    <header class="empresas__header">
      <h2 class="empresas_home__heading heading">Solicitud de ambulancia para traslados locales</h2>
      <div class="empresas_home__text">Te apoyamos durante el traslado de pacientes de un lugar a otro el día que nos indiques.</div>
    </header>
    <v-card
      flat
      :loading="cargando"
      :disabled="cargando"
    >
      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="etl.tipo_etl"
            @change="_handlePrice"
            label="Tipo de traslado*"
            :items="selects.tiposTraslado"
            filled
          />
        </v-col>
        <v-col
          cols="12"
          sm="8"
        >
          <v-text-field
            :disabled="!etl.tipo_etl"
            v-model="etl.paciente_etl"
            label="Nombre del paciente*"
            filled
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            :disabled="!etl.paciente_etl"
            v-model="etl.condicion_etl"
            label="Condición del paciente"
            :items="selects.condicionPaciente"
            filled
            @change="_handlePrice"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            :disabled="!etl.paciente_etl"
            v-model="etl.requerimientos_etl"
            label="Requerimientos del paciente"
            :items="selects.requerimientosPac"
            multiple
            filled
            @change="_handlePrice"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          lg="8"
        >
          <gMapsAutocompletePlaces
            :disabled="!etl.paciente_etl"
            @change="_handleLugar($event,'lugar_inicio_eva')"
            label="Lugar de origen*"
            hint="Preferentemente seleccione una opción el lugar de la lista el cual contenga número del domicilio"
            persistentHint
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          lg="4"
        >
          <v-text-field
            :disabled="!eva.lugar_inicio_eva"
            label="Notas sobre el lugar de origen"
            v-model="eva.lugar_inicio_notas_eva"
            filled
            lugar_inicio_eva
            hint="Por ejemplo, entre que calles o alguna referencia para ubicar el domicilio"
            persistent-hint
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          lg="8"
        >
          <gMapsAutocompletePlaces
            :disabled="!eva.lugar_inicio_eva"
            @change="_handleLugar($event,'lugar_termino_eva')"
            label="Lugar de destino*"
            hint="Preferentemente seleccione una opción el lugar de la lista el cual contenga número del domicilio"
            persistentHint
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          lg="4"
        >
          <v-text-field
            :disabled="!eva.lugar_termino_eva"
            label="Notas sobre el lugar de destino"
            v-model="eva.lugar_termino_notas_eva"
            filled
            hint="Por ejemplo, entre que calles o alguna referencia para ubicar el domicilio"
            persistent-hint
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <DatePicker
            :disabled="!eva.lugar_termino_eva"
            label="Fecha del traslado*"
            v-model="eva.fecha_inicio_eva"
            sinceToday
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <TimePicker
            :disabled="!eva.fecha_inicio_eva"
            v-model="eva.hora_inicio_eva"
            label="Hora del traslado*"
            :horaInicio="_hourToInt(configuracion.atencion_inicio_cotl)"
            :horaFin="_hourToInt(configuracion.atencion_fin_cotl)"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
        >
          <v-card
            outlined
            elevation="0"
          >
            <v-list subheader>
              <v-subheader v-text="'RESUMEN'" />
              <v-list-item v-if="etl.tipo_etl">
                <v-list-item-content>
                  <v-list-item-title v-text="`Traslado local ${etl.tipo_etl}`" />
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title v-html="`<strong>Total:</strong> ${$nF(total)}`" />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <AmbulanciasReservarAcciones
        :tipo="tipo"
        :showActions="eva.hora_inicio_eva"
        :total="total"
        :eva="eva"
        :data="etl"
        @msg="$emit('msg',$event)"
        @httpError="$emit('httpError',$event)"
      />
    </v-card>
  </section>
</template>
<script>
import standard from "@/mixins/standard";
import ambulanciasConfig from "@/mixins/ambulanciasConfig.js";
import HeaderTitle from "@/components/header/HeaderTitle.vue";
export default {
  name: "AmbulanciasTrasladosLocales",
  mixins: [standard, ambulanciasConfig],
  components: {
    HeaderTitle,
  },
  data() {
    return {
      tipo: "trasladoslocales",
      etl: {
        tipo_etl: null,
        paciente_etl: null,
        condicion_etl: null,
        requerimientos_etl: null,
      },
      total: 0,
      configuracion: {
        anticipacion_horas_cotl: null,
        atencion_fin_cotl: null,
        atencion_inicio_cotl: null,
        cancelacion_falta_pago_cotl: null,
        traslado_completo_cotl: null,
        traslado_sencillo_cotl: null,
        trasladocompleto: {
          costo_ser: null,
          desc_ser: null,
          estatus_ser: null,
          id_cta_ser: null,
          id_ser: null,
          id_tin_ser: null,
          tipo_ser: null,
        },
        trasladosencillo: {
          costo_ser: null,
          desc_ser: null,
          estatus_ser: null,
          id_cta_ser: null,
          id_ser: null,
          id_tin_ser: null,
          tipo_ser: null,
        },
      },
    };
  },
  methods: {
    _handlePrice() {
      const { tipo_etl, requerimientos_etl, condicion_etl } = this.etl;
      let total = parseFloat(
        tipo_etl === "sencillo" || tipo_etl === "completo"
          ? this.configuracion["traslado" + tipo_etl].costo_ser
          : 0
      );
      const por = tipo_etl === "completo" ? 2 : 1;
      if (condicion_etl === "covid") {
        const covid =
          parseFloat(this.configuracion["trasladocovid"].costo_ser) * por;
        total = total + covid;
      }
      if (requerimientos_etl) {
        if (requerimientos_etl.includes("canalizar")) {
          total =
            total + parseFloat(this.configuracion.oxigenacion.costo_ser) * por;
        }
        if (requerimientos_etl.includes("oxigeno")) {
          total =
            total + parseFloat(this.configuracion.canalizacion.costo_ser) * por;
        }
      }

      this.total = total;
    },
  },
  created() {
    this._getConfigAmbu(this.tipo);
  },
};
</script>